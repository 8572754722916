import React, { useState } from 'react';
import styled from 'styled-components';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { NewsletterInline } from './Newsletter';
import '../styles/main.css';
import { Cookie } from './Cookie';
import { SEO } from './SEO';

const PageScroll = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: ${props => props.width * 100}%;
    background-color: #000;
    height: 4px;
    z-index: 10;
`

export const PageWrapper = ({ children, seo }) => {
    const isBrowser = () => typeof window !== "undefined"
    const [scrollPercentage, setScrollPercentage] = useState(0)

    const listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        setScrollPercentage(scrolled)
    }

    isBrowser() && window.addEventListener('scroll', listenToScroll)

    return (
        <main>
            <PageScroll width={scrollPercentage} />
            <SEO
              title={seo?.title}
              meta={seo?.meta}
              description={seo?.description}
              keywords={seo?.keywords}
              thumbnail={seo?.thumbnail}
              path={seo?.path}
            />
            <Navbar />
            {children}
            <NewsletterInline title="Newsletter" />
            <Cookie />
            <Footer />
        </main>
    )
}