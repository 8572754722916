import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../utils/styling';
import { Link } from './Link';
import { Section } from './Section';

const BaseFooter = ({ className }) => {
    return (
        <Section spacing={['mt-2']}>
            <nav className={className}>
                <div>
                    <Link href="/">designkeepsmealive.com</Link>
                </div>
                <div className="right">
                    <Link href="/">Home</Link>
                    <Link href="/about/">About</Link>
                    <Link href="/imprint/">Imprint</Link>
                    <Link href="/data-privacy/">Data Privacy</Link>
                </div>
            </nav>
        </Section>
    )
}

export const Footer = styled(BaseFooter)`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
    row-gap: 1.5rem;
    
    ${breakpoints("grid-template-columns", "", [
      { 800: "1fr 1fr" },
    ])};

    .right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;