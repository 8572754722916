import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import classNames from 'classnames';
// in your cookie banner
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { breakpoints } from '../utils/styling';
import Cookies from 'js-cookie'

const BaseCookie = ({ className }) => {
    const location = useLocation()
    const acceptedCookies = new Boolean(Cookies.get('DKMA_CONSENT')).valueOf();
    const [isAccpeted, setIsAccepted] = useState(acceptedCookies === true);
    const [hide, setHide] = useState(false);

    const accept = () => {
        setHide(true)
        setTimeout(() => {
            initializeAndTrack(location)
            setIsAccepted(true);
            Cookies.set('DKMA_CONSENT', 'true', { expires: 365 })
        }, 1000)
    }

    const decline = () => {
        setHide(true)
        setTimeout(() => {
            setIsAccepted(false);
        }, 1000)
    }

    if (isAccpeted) {
        initializeAndTrack(location);
        return null;
    }

    return (
        <div className={classNames(className, {
            isAccpeted: isAccpeted,
            hide: hide,
        })}>
            We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking okay, you consent to the use of all the cookies.
            <div className='buttons'>
                <div className="button" onClick={accept}><span className='icon'><FontAwesomeIcon size='xs' icon={faCheck} /></span> Okay</div>
                <div className="button" onClick={decline}>Never Ever</div>
            </div>
        </div>
    )
}

const hideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-25px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(200%);
  }
`;

export const Cookie = styled(BaseCookie)`
    position: fixed;
    background-color: #000;
    bottom: 1rem;
    left: 1rem;
    color: #fff;
    padding: 1rem;
    border-radius: 3px;
    max-width: calc(100% - 4rem);
    z-index: 30;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3);

    ${breakpoints("max-width", "", [
      { 570: "400px" },
    ])};

    &.hide {
        animation: ${hideDown} 1s ease both;
    }

    .buttons {
        margin-top: 10px;

        .button {
            padding: 5px 10px;
            background-color: rgba(255,255,255,.2);
            border-radius: 3px;
            display: inline-block;
            margin: 5px 10px 0 0;
            cursor: pointer;
            transition: 250ms ease-in-out;

            .icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: rgba(255,255,255,.4);
            }
        }
    }
`;