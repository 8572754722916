import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../utils/styling';
import { StyledHeader } from './Elements';
import {Section} from './Section';
import { StickyInfo } from './StickyInfo';


const BaseNewsletterInline = ({ className, title = "Newsletter" }) => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');

    //FORM_URL should be the same as the form action url pointed out above
    const FORM_URL = `https://app.convertkit.com/forms/2871217/subscriptions`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            setEmail('');
            const json = await response.json();
            if (json.status === 'success') {
                setStatus('SUCCESS');
                return;
            }
        } catch (err) {
            setStatus('ERROR');
            console.log(err);
        }
    };

    const handleInputChange = event => {
        const {value} = event.target;
        setEmail(value);
    }

    return (
        <Section spacing={['mt-2', 'mb-2']}>
            <StickyInfo left={title} />
            <StyledHeader>
                <h1 className="display">
                    Get the hotest topics
                </h1>
                <div className="right font-size sm">
                    No spam! Promise! We will only send you newsletter on relevant articles based on what you read.
                    So don't worry.
                </div>
            </StyledHeader>
            <form
                className="sub__form"
                action={FORM_URL}
                method="post"
                onSubmit={handleSubmit}
                className={className}
            >
                <input
                    type="email"
                    aria-label="Your email"
                    name="email_address"
                    placeholder="Your email address"
                    onChange={handleInputChange}
                    value={email}
                    required
                    className="input"
                    placeholder="Join the community"
                />
                <button type="submit" className="button">Join</button>
            </form>
            {status === 'SUCCESS' && <p style={{ fontWeight: 'bold' }}>Please confirm your subscription!</p>}
            {status === 'ERROR' && <p style={{ fontWeight: 'bold' }}>Oops, Something went wrong! Please try again later.</p>}
        </Section>
    )
}

export const NewsletterInline = styled(BaseNewsletterInline)`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;

    ${breakpoints("flex-direction", "", [
      { 800: "row" },
    ])};

    .input {
        padding: 1rem;
        flex-grow: 1;
        border: 4px solid #000;
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 0px !important;

        ${breakpoints("padding", "", [
            { 800: "2rem" },
        ])};
        ${breakpoints("font-size", "", [
            { 800: "2rem" },
        ])};
    }

    .button {
        padding: 1rem 2rem;
        border: 2px solid #000;
        color: #fff;
        background-color: #000;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;

        ${breakpoints("padding", "", [
            { 800: "2rem 4rem" },
        ])};
        ${breakpoints("font-size", "", [
            { 800: "2rem" },
        ])};
    }
`;