import React from 'react';
import { Helmet } from 'react-helmet';

export const SEO = ({
    title = '',
    keywords = '',
    description = '',
    thumbnail = '',
    path = '',
}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title} | designkeepsmealive.com</title>

            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={thumbnail} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={thumbnail} />
            <meta property="og:url" content={`https://designkeepsmealive.com${path}`} />

            <meta name="robots" content="all"/>
            <meta name="robots" content="noindex"/>
            <meta name="robots" content="nofollow"/>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="robots" content="index, follow" /> 
            <meta name="robots" content="none"/>
            <meta name="robots" content="noarchive"/>
            <meta name="robots" content="notranslate"/>
            <meta name="robots" content="noimageindex"/>
            <meta name="robots" content="nosnippet"/>

            <link rel="canonical" href="https://designkeepsmealive.com" />
        </Helmet>
    )
}